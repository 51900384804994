import * as React from 'react';
import {useTranslation} from 'react-i18next';
import withI18next from '../../plugins/gatsby-plugin-i18next/withI18next';
import MainLayout from '../layouts/main';
import constants from '../utils/constants';

function Page() {
  const {t} = useTranslation();
  return (
    <MainLayout currentPage={constants.PAGE_THANK_YOU}>
      <h1>{t(constants.PAGE_THANK_YOU + '-title')}</h1>
      <p>{t(constants.PAGE_THANK_YOU + '-desc')}</p>
    </MainLayout>
  );
}

export default withI18next()(Page);
